import gql from 'graphql-tag';

export const Toggle_reservation_attachment_acceptanceMutation = gql`
  mutation toggle_reservation_attachment_acceptance($camp_participant_id: ID!, $key: String!)
  {
    toggle_reservation_attachment_acceptance(camp_participant_id: $camp_participant_id, key: $key)
    {
      status
      camp_participant {
          id
          unaccepted_attachments
          attachments {
            id
            key
            file_name
            date_created
            size
            accepted
          }
          events_array
        }
    }
  }`;

export const Delete_reservation_attachmentMutation = gql`mutation
  delete_reservation_attachment($camp_participant_id: ID!, $key: String!, $comment: String)
  {
    delete_reservation_attachment(camp_participant_id: $camp_participant_id, key: $key, comment: $comment)
    {
      status
      camp_participant {
          id
          unaccepted_attachments
          attachments {
            id
            key
            file_name
            date_created
            size
            accepted
          }
          events_array
        }
    }
  }`;
