export enum S3_FileExistsErrorHandling_Type
{
  THROW_ERROR = 'THROW_ERROR',
  OVERWRITE = 'OVERWRITE',
  RENAME = 'RENAME',
}

export enum S3_FileContext_Type
{
  RESERVATION_ATTACHMENTS = 'RESERVATION_ATTACHMENTS',
  RESERVATION_CONTRACT = 'RESERVATION_CONTRACT',
  APPLICATION = 'APPLICATION',
}
