export class FileAttachment {
  id: string;
  key: string;
  file_name: string;
  icon_file_key?: string;
  date_created: Date;
  size: number;
  accepted: boolean;
}


export const FileAttachmentEmptyEntity: FileAttachment = {
  id: '',
  key: '',
  file_name: '',
  icon_file_key: null,
  date_created: new Date(),
  size: 0,
  accepted: false,
};
