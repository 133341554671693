import gql from 'graphql-tag';

export const Generate_presigned_url_for_reservations_attachment_download = gql`
  query generate_presigned_url_for_reservations_attachment_download ($camp_participant_id: ID!, $key: String!)
  {
    generate_presigned_url_for_reservations_attachment_download(camp_participant_id: $camp_participant_id, key: $key)
    {
      status
      url
    }
  }`;

export const S3_file_upload_create_presigned_post_attributes = gql`
  query S3_file_upload_create_presigned_post_attributes($context: S3_FileContext_Type!, $parent_object_id: ID!, $file_already_exists_handling: S3_FileExistsErrorHandling_Type!, $file_name: String!){
    S3_file_upload_create_presigned_post_attributes(context: $context, parent_object_id: $parent_object_id, file_already_exists_handling: $file_already_exists_handling, file_name: $file_name){
      status
      confirmed_file_name
      formInputs
      formAttributes
      full_file_key
    }
  }`;
